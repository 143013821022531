import React, { useState } from "react";
import { BsStarFill } from "@react-icons/all-files/bs/BsStarFill";

import { Flex, Box, FlexProps } from "Atoms";

type SetFieldValue = (rating: number) => void;

type Props = {
    onSetOverallRating: SetFieldValue;
    size?: "sm" | "md" | "lg";
} & FlexProps;

export const FeedbackRating: React.FC<Props> = ({ onSetOverallRating, size = "lg", ...rest }) => {
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);

    const onHandleClick = (rating: number) => {
        setRating(rating);
        onSetOverallRating(rating);
    };

    const sizeToStarSize = {
        sm: "2rem",
        md: "3rem",
        lg: "4rem"
    };

    // @ts-ignore
    const starSize = sizeToStarSize[size];

    return (
        <Flex pt={{ xs: 1, lg: 6 }} {...rest}>
            {[...Array(5)].map((_, index: number) => {
                const starRating = (index += 1);
                return (
                    <Box
                        as={BsStarFill}
                        fontSize={starSize}
                        ml={1}
                        key={starRating}
                        onClick={() => onHandleClick(starRating)}
                        color={starRating <= (hover || rating) ? "gold" : "gray.500"}
                        cursor="pointer"
                    />
                );
            })}
        </Flex>
    );
};

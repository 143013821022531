import React, { useRef, useState, useEffect } from "react";
import Media from "react-media";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";

import { Box, PseudoBox, Flex } from "Atoms";
import { useEventCallback } from "Hooks";
import { OnlineMenus, OnlineMenu, OnlineEatingPreference } from "Types";
import { useOnline } from "Providers";
import { ExpressOnlineOrderCategoriesNavigation } from "./components";

type Props = {
    onlineMenus: OnlineMenus;
    onlineMenu: OnlineMenu | null;
    setActiveMenu: (onlineMenu: OnlineMenu) => void;
    setActiveMenuProdCatId: (menuId: string) => void;
    activeMenuProdCatId: string;
    handleEatingOptionClick: (eatingSelection: OnlineEatingPreference) => void;
};

export const ExpressOnlineOrderMenusNavigation: React.FC<Props> = ({
    onlineMenus,
    onlineMenu,
    setActiveMenu,
    activeMenuProdCatId,
    handleEatingOptionClick,
    ...rest
}) => {
    const categoryRef = useRef<any>(null);

    const {
        browseMode: { hasNoActiveMenus }
    } = useOnline();

    const [categoryScrollState, setCategoryScrollState] = useState({
        scrollLeft: 0,
        scrollWidth: 0,
        offsetWidth: 0
    });

    useEffect(() => {
        if (categoryRef && categoryRef.current) {
            setCategoryScrollState((currState: any) => ({
                ...currState,
                scrollWidth: categoryRef.current.scrollWidth,
                offsetWidth: categoryRef.current.offsetWidth
            }));
        }
    }, []);

    useEventCallback({
        eventName: "resize",
        callback: () => {
            if (categoryRef && categoryRef.current) {
                setCategoryScrollState((currState: any) => ({
                    ...currState,
                    scrollWidth: categoryRef.current.scrollWidth,
                    offsetWidth: categoryRef.current.offsetWidth
                }));
            }
        }
    });

    if (!onlineMenu) return <div />;
    const noUpsellOnlineProductCategories = onlineMenu.onlineProductCategories.filter(
        menuCat => !(menuCat.upsellCategory || menuCat.upsellCategoryAtPayment)
    );

    const handleScrollTo = (ref: any, scrollState: any, direction: string) => {
        const el = ref.current;
        const { scrollLeft } = scrollState;

        el.scrollTo({
            left: direction === "right" ? scrollLeft + 200 : direction === "left" ? scrollLeft - 200 : 0,
            behavior: "smooth"
        });
    };

    return (
        <Flex direction="column" overflowY="hidden" paddingY={4} id="testing-testing">
            <Media queries={{ small: { maxWidth: 991 } }}>
                {matches =>
                    matches.small && !hasNoActiveMenus && onlineMenus.length === 1 ? (
                        <Box
                            overflowX="auto"
                            display="flex"
                            id="hidden-scroll"
                            ref={categoryRef}
                            onScroll={() => {
                                setCategoryScrollState((currState: any) => ({
                                    ...currState,
                                    scrollLeft: categoryRef.current.scrollLeft
                                }));
                            }}
                        >
                            <Box display="flex" flexWrap="nowrap">
                                {categoryScrollState.scrollLeft > 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        left={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="30px"
                                        background="linear-gradient(90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(categoryRef, categoryScrollState, "left");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronLeft} height="100%" />
                                    </PseudoBox>
                                )}
                                <ExpressOnlineOrderCategoriesNavigation
                                    onlineProductCategories={noUpsellOnlineProductCategories}
                                    activeMenuProdCatId={activeMenuProdCatId}
                                    small={matches.small}
                                    {...rest}
                                />
                                {categoryScrollState.scrollLeft <
                                    categoryScrollState.scrollWidth - categoryScrollState.offsetWidth - 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        right={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="30px"
                                        background="linear-gradient(-90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(categoryRef, categoryScrollState, "right");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronRight} height="100%" />
                                    </PseudoBox>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <Flex align="center">
                            <Flex
                                mr={8}
                                overflowX="auto"
                                flex="1"
                                id="hidden-scroll"
                                ref={categoryRef}
                                onScroll={() => {
                                    setCategoryScrollState((currState: any) => ({
                                        ...currState,
                                        scrollLeft: categoryRef.current.scrollLeft
                                    }));
                                }}
                            >
                                <Flex flexWrap="nowrap">
                                    <ExpressOnlineOrderCategoriesNavigation
                                        onlineProductCategories={noUpsellOnlineProductCategories}
                                        activeMenuProdCatId={activeMenuProdCatId}
                                        small={matches.small}
                                        {...rest}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    )
                }
            </Media>
        </Flex>
    );
};

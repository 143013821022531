import React, { useState } from "react";
import { Link as RLink } from "react-router-dom";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";

import { Link, NewGrid as Grid, Box, Flex, Text } from "Atoms";
import { formatShopUrlName } from "Utils";
import { useLanguage } from "Providers";
import { LinkType } from "Types";

type Props = {
    publicShops: { name: string; id: string; publicId: string }[];
    isClickable: boolean;
};

const NUM_SHOPS_TO_SHOW = 3;

export const ShopList: React.FC<Props> = ({ publicShops, isClickable }) => {
    const { translate } = useLanguage();
    const [isAllShopsShown, setIsAllShopsShown] = useState<boolean>(false);

    const croppedList =
        isAllShopsShown || publicShops.length <= NUM_SHOPS_TO_SHOW
            ? publicShops
            : publicShops.slice(0, NUM_SHOPS_TO_SHOW);

    return (
        <Grid templateColumns={["1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"]} gap={4}>
            {croppedList.map(shop => {
                const to = `/restaurant/${formatShopUrlName(shop.name)}/${shop.publicId}/order`;
                return (
                    <Box key={shop.name}>
                        {isClickable ? (
                            <Link as={RLink as LinkType} color="blue.500" to={to}>
                                {shop.name}
                            </Link>
                        ) : (
                            <Text mb={0} color="gray.600">
                                {shop.name}
                            </Text>
                        )}
                    </Box>
                );
            })}
            {publicShops.length > NUM_SHOPS_TO_SHOW && (
                <Flex alignItems="center" width="100%">
                    <Text
                        onClick={() => setIsAllShopsShown(!isAllShopsShown)}
                        fontSize="1.1rem"
                        mb={0}
                        cursor="pointer"
                        style={{ textDecoration: "underline" }}
                    >
                        {translate(isAllShopsShown ? "showLess" : "showMore")}
                    </Text>
                    <Box as={isAllShopsShown ? FiMinus : FiPlus} size="1.1rem" ml={1} />
                </Flex>
            )}
        </Grid>
    );
};

import React from "react";
import { AiOutlineDisconnect } from "@react-icons/all-files/ai/AiOutlineDisconnect";

import { Modal, ModalHeader, ModalBody, ModalCloseBtn } from "Molecules";
import { Box, Button, Flex, Stack, Text } from "Atoms";
import { useOrderWindowSocketStore, useOrderWindowsStore } from "Stores";
import {
    FullOrderWindowCustomer,
    IModalContext,
    modals,
    getFullOrderWindowCustomersFromOrderWindow,
    useLanguage
} from "Providers";
import { OrderWindow, OrderWindowCustomerStatus } from "Types";
import { getCleanGroupOrderNickname } from "../../../../customer/shared/groupOrder/utils";
import { sortOrderWindowCustomers } from "../../../../customer/shared/groupOrder/utils/orderWindowCustomersHelpers";

type Props = {
    username?: string;
    tableId?: string;
    clientId?: string;
};

export const GroupOnlineOrderCustomersModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const { multipleEnterOrderWindow, declineEnterOrderWindow, nickname, tableId } = useOrderWindowSocketStore();
    // Don't remove 'orderWindows', we need it for trigger zustand updates
    const { orderWindows, getActiveOrderWindow } = useOrderWindowsStore();
    const { translate } = useLanguage();

    const orderWindow = getActiveOrderWindow();
    const orderWindowCustomers = getFullOrderWindowCustomersFromOrderWindow(orderWindow as OrderWindow);

    const customers = sortOrderWindowCustomers(orderWindowCustomers) as FullOrderWindowCustomer[];

    const requestToJoinCustomers =
        orderWindow?.customers?.filter(customer => customer.status === OrderWindowCustomerStatus.REQUEST_TO_JOIN) ?? [];
    const restOfCustomers = customers;

    const _tableId = modalContent?.tableId ?? tableId;

    const onClose = () => closeModal(modals.groupOnlineOrderCustomersModal);

    const handleAcceptCustomers = () => {
        if (_tableId) {
            multipleEnterOrderWindow(_tableId, requestToJoinCustomers);
            onClose();
        }
    };

    const handleDeclineCustomer = (nickname: string, clientId: string) => {
        if (_tableId) {
            declineEnterOrderWindow(_tableId, nickname, clientId);
        }
    };

    return (
        <Modal open onClose={onClose} isScrolling>
            <ModalHeader>
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody>
                <Stack stretch={8}>
                    {!!requestToJoinCustomers.length && (
                        <Box>
                            <Text fontSize="xl" fontWeight="700">
                                {translate("wantsToJoin")}
                            </Text>
                            <Stack stretch={4}>
                                {requestToJoinCustomers?.map(customer => (
                                    <Flex key={customer.nickname} h="auto" justify="space-between" align="center">
                                        <Text fontSize="lg">{getCleanGroupOrderNickname(customer.nickname)}</Text>
                                        <Button
                                            rounded="lg"
                                            themeColor="red"
                                            onClick={() => handleDeclineCustomer(customer.nickname, customer.clientId)}
                                        >
                                            {translate("decline")}
                                        </Button>
                                    </Flex>
                                ))}
                            </Stack>
                            <Button themeColor="green" rounded="lg" onClick={handleAcceptCustomers} fullWidth mt={8}>
                                {translate("allowAll")}
                            </Button>
                        </Box>
                    )}
                    <Box>
                        <Text fontSize="xl" fontWeight="700">
                            {translate("yourGroup")}
                        </Text>
                        <Stack stretch={4}>
                            {restOfCustomers?.map(customer => {
                                const isDisconnected = customer.status === OrderWindowCustomerStatus.DISCONNECTED;
                                const isMe = customer.nickname === nickname;

                                const cleanGroupOrderNickname = getCleanGroupOrderNickname(customer.nickname);

                                return (
                                    <Stack
                                        key={customer.nickname}
                                        h="auto"
                                        isInline
                                        align="center"
                                        stretch={1}
                                        color={isDisconnected ? "red.500" : "gray.900"}
                                    >
                                        {isDisconnected && <Box as={AiOutlineDisconnect} fontSize="xl" />}
                                        <Text fontSize="lg">
                                            {isMe
                                                ? `${cleanGroupOrderNickname} (${translate("you")})`
                                                : cleanGroupOrderNickname}
                                        </Text>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Box>
                </Stack>
            </ModalBody>
        </Modal>
    );
};

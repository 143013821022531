import React from "react";
import { Form, Formik } from "formik-next";
import * as Yup from "yup";

import { Button, Center, Header, Text, NewContainer, Flex, Spinner, Image, Box, Stack } from "Atoms";
import { Alert, AlertBody, AlertTitle, AlertIcon, FormInput } from "Molecules";
import { useMothershipQuery, useSwitch } from "Hooks";
import { useOrderWindowSocketStore, useQoplaStore } from "Stores";
import { doesContainsSpecialCharacters } from "Utils";
import { OrderWindowCustomer } from "Types";
import { useLanguage, useTheme } from "Providers";
import { LanguagePicker } from "Components";
import { GET_SHOP_TABLE } from "GraphQLQueries";
import { getCleanGroupOrderNickname } from "../../../../customer/shared/groupOrder/utils";

type Props = {
    customers: OrderWindowCustomer[];
    tableId: string;
    shopLogo: string;
    onSubmit: (nickname: string) => Promise<boolean>;
};

export const GroupOnlineOrderWelcome: React.FC<Props> = ({ customers, onSubmit, tableId, shopLogo }) => {
    const { open: shouldShowPendingInvite, onToggle: onToggleShowPendingInvite } = useSwitch();

    const { selectedShop } = useQoplaStore();
    const { translate, translateWithArgument } = useLanguage();
    const { isDeclined } = useOrderWindowSocketStore();
    const { colors } = useTheme();

    const { data, loading } = useMothershipQuery(GET_SHOP_TABLE, { variables: { tableId } });

    if (loading) {
        return (
            <NewContainer maxWidth="100%" h="100%" bg="gray.100">
                <Center>
                    <Spinner />
                </Center>
            </NewContainer>
        );
    }

    if (!data?.getShopTableById) {
        return (
            <Center h="full" w="full" direction="column">
                <Alert
                    position="absolute"
                    zIndex="1500"
                    status="warning"
                    variant="subtle"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    height="100%"
                    width="100%"
                >
                    <AlertIcon size="50px" mr={0} />
                    <AlertTitle mt={4} mb={2} fontSize="2xl">
                        <Text fontSize="lg">{translate("noTableFound")} :(</Text>
                    </AlertTitle>
                    <AlertBody display="flex" maxWidth="sm" fontSize="lg">
                        <Stack stretch={1}>
                            <Text>{translate("scanAgainOrContactStaff")}</Text>
                            <Text>tableId: {tableId}</Text>
                        </Stack>
                    </AlertBody>
                </Alert>
            </Center>
        );
    }

    /**
     * Scenario: You pay for yourself and get redirected here
     *  When paying, you still have the CONNECTED status and we
     *  only display connected users here. Therefor we need to filter
     *  out the paid customers.
     *
     *  Maybe setting the customer with DISCONNECTED status is preferred
     *  when paying? Not sure...
     *
     */
    const _customers = customers?.filter(customer => !customer.paid);
    const hasCustomers = !!_customers?.length;

    const validationSchema = Yup.object().shape({
        nickname: Yup.string()
            .max(20, translateWithArgument("formErrorMaxNoCharacters", 20))
            .test("nickname", translate("formErrorNotEmpty"), (value: string) => {
                const isOnlyWhiteSpace = value.trim().length === 0;

                return !isOnlyWhiteSpace;
            })
            .test("nickname", translate("formErrorNoSpecialCharacters"), (value: string) => {
                const doesNicknameContainSpecialCharacters = doesContainsSpecialCharacters(value);

                return !doesNicknameContainSpecialCharacters;
            })
            .required(translate("required"))
    });

    return (
        <Box position="relative">
            <Box position="absolute" top="0" right="0" zIndex={1} p={6}>
                <LanguagePicker rounded="lg" />
            </Box>
            <Image src={selectedShop?.bannerUrl} h="100vh" w="100%" objectFit="cover" filter="brightness(0.3)" />
            <Center position="absolute" top="0" direction="column" w="100%">
                <Center direction="column" p={4} w="100%">
                    <Center h="auto" direction="column" mb={8}>
                        <Image src={shopLogo} maxW={24} mb={3} />
                        <Header color="white" mt={0}>
                            {translate("welcome")}
                        </Header>
                    </Center>
                    {shouldShowPendingInvite ? (
                        <Flex maxWidth="40rem" width="100%" boxShadow="lg" h="auto">
                            <Alert
                                zIndex="1500"
                                status={isDeclined ? "error" : "info"}
                                variant="subtle"
                                flexDirection="column"
                                justifyContent="center"
                                textAlign="center"
                                height="100%"
                                width="100%"
                                rounded="lg"
                            >
                                <AlertIcon size="40px" mr={0} />
                                <AlertTitle fontSize="xl" mt={2}>
                                    {isDeclined
                                        ? translate("declineGroupOrderRequest")
                                        : translate("waitForGroupToAccept")}
                                </AlertTitle>
                                {!isDeclined && (
                                    <AlertBody display="flex" maxWidth="sm" mt={4}>
                                        <Spinner margin="0" color={colors.blue["500"]} size="small" />
                                    </AlertBody>
                                )}
                            </Alert>
                        </Flex>
                    ) : (
                        <Flex
                            maxWidth="40rem"
                            width="100%"
                            p={4}
                            bg="white"
                            direction="column"
                            rounded="md"
                            borderWidth={1}
                            borderRadius={8}
                            boxShadow="lg"
                            borderColor="gray.100"
                            borderStyle="solid"
                            h="auto"
                        >
                            <Header textAlign="center" as="h3" size="xl" mb={4}>
                                {data.getShopTableById.name}
                            </Header>
                            {hasCustomers && (
                                <Box mb={6} flex="1">
                                    <Flex
                                        h="auto"
                                        justify="space-between"
                                        fontSize="lg"
                                        mb={2}
                                        fontWeight="bold"
                                        color="gray.700"
                                    >
                                        <Text as="span">{translate("currentGroup")}</Text>
                                        <Text as="span">{_customers.length} st</Text>
                                    </Flex>
                                    <Stack stretch={1} ml={2} maxH="100px" overflow="auto">
                                        {_customers?.map(customer => (
                                            <Text key={customer.nickname}>
                                                {getCleanGroupOrderNickname(customer.nickname)}
                                            </Text>
                                        ))}
                                    </Stack>
                                </Box>
                            )}
                            <Formik
                                initialValues={{ nickname: "" }}
                                validationSchema={validationSchema}
                                validateOnBlur={false}
                                onSubmit={async values => {
                                    const shouldShowPendingInvite = await onSubmit(values.nickname);
                                    if (shouldShowPendingInvite) {
                                        onToggleShowPendingInvite();
                                    }
                                }}
                            >
                                {({ isValid, isSubmitting, values }) => {
                                    const hasNoNickname = !values.nickname;
                                    const isDisabled = hasNoNickname || !isValid;

                                    return (
                                        <Form>
                                            <FormInput
                                                name="nickname"
                                                placeholder={translate("yourName")}
                                                size="lg"
                                                rounded="lg"
                                                fullWidth
                                            />
                                            <Button
                                                type="submit"
                                                isDisabled={isDisabled}
                                                isLoading={isSubmitting}
                                                size="lg"
                                                rounded="lg"
                                                fullWidth
                                                themeColor="green"
                                            >
                                                {translate("startOrdering")}
                                            </Button>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Flex>
                    )}
                </Center>
            </Center>

            <Center w="100%" h="auto" position="absolute" zIndex={1} left={0} right={0} bottom={0} pb={6}>
                <Box background="white" px={4} py={2} rounded="lg">
                    <Text fontSize="xl" textAlign="center">
                        <Text as="span">Powered by </Text>
                        <Text as="span" fontFamily="qopla" fontSize="2xl" color="newPrimary">
                            Qopla
                        </Text>
                    </Text>
                </Box>
            </Center>
        </Box>
    );
};

import React from "react";

import { EatingPreference, Languagekey, useLanguage } from "Providers";
import { RadioButtonGroup } from "Molecules";
import { Box, CustomRadioButton } from "Atoms";
import { onlineEatingPreferencesIcons } from "OnlineUtils";
import { OnlineEatingPreference } from "Types";

type Props = {
    isTakeAway: boolean;
    handleSetFoodOptions: any;
    eatingOptions: OnlineEatingPreference[];
    eatingPreference: OnlineEatingPreference;
};

export const ExpressOnlinePickupButton: React.FC<Props> = ({
    handleSetFoodOptions,
    eatingPreference,
    eatingOptions
}) => {
    const { translate } = useLanguage();

    const getEatingPreference = (value: string) => {
        if (value === "takeAway") {
            return EatingPreference.TAKE_AWAY;
        } else if (value === "eatHere") {
            return EatingPreference.EAT_HERE;
        }
    };

    const options = eatingOptions.map(eatingOption => {
        return onlineEatingPreferencesIcons[eatingOption];
    });

    const { text: value } = onlineEatingPreferencesIcons[eatingPreference];

    return (
        <Box>
            <RadioButtonGroup
                name="pickupOption"
                value={value}
                activeThemeColor="green"
                inactiveThemeColor="gray"
                onValueChanged={(selectedValue: string) => handleSetFoodOptions(getEatingPreference(selectedValue))}
                rounded="md"
            >
                {options.map((option, idx) => {
                    return (
                        <CustomRadioButton key={idx} value={option.text} rightIcon={option.icon} size="lg">
                            {translate(option.text as Languagekey)}
                        </CustomRadioButton>
                    );
                })}
            </RadioButtonGroup>
        </Box>
    );
};

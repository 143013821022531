import React from "react";

import { Flex, Box, Header, Text, NewButton, CopyrightText } from "Atoms";
import { useOnline } from "Providers";

export const ExpressMiniFooter: React.FC<{}> = () => {
    const { resetFoodOptions } = useOnline();
    const onQoplaDoubleClick = () => {
        resetFoodOptions();
        window.location.reload();
    };

    return (
        <Box as="footer" paddingX={12} paddingY={4} bg="gray.200" gridRow="none" userSelect="none">
            <Flex justify="space-between" alignItems="center">
                <Flex alignItems="center" whiteSpace="pre-wrap">
                    <NewButton variant="ghost" onDoubleClick={onQoplaDoubleClick}>
                        <Text m={0} mr={2} color="newPrimary">
                            Powered by
                        </Text>
                        <Header m={0} as="h3" size="xl" color="newPrimary" mb={[2, 12, 4]} fontFamily="qopla">
                            Qopla
                        </Header>
                    </NewButton>
                </Flex>
                <CopyrightText m="0" color="newPrimary" />
            </Flex>
        </Box>
    );
};

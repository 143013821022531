import React, { useState } from "react";
import { VscDebugRestart } from "@react-icons/all-files/vsc/VscDebugRestart";
import { RemoveScroll } from "react-remove-scroll";

import { Flex, Image, Header, Button, Box, Text } from "Atoms";
import { OnlineEatingPreference, Shop, FoodOptions } from "Types";
import { SwishLogoPng } from "Assets";
import { LanguageText } from "Components";
import { useOrientation } from "Hooks";
import { Languagekey, useLanguage } from "Providers";
import { ExpressEatingOptions } from "../../checkout/components";
import { useOnlineExpressIdleTimer } from "../../shared";
import { onlineEatingPreferencesIcons } from "OnlineUtils";
import { ExpressOnlineSecretModal } from "./";
import { Timer } from "TempUtils";
import { LOCAL_STORAGE_CONSTANTS } from "Constants";
import { ExpressOnlineSettingsType } from "../../../utils";
import { PoweredByQoplaLogo } from "../../../shared/components";

type CommonProps = {
    selectedShop: Shop;
};

type Props = {
    shopIsClosed: boolean;
    headerOpen: boolean;
    hasActiveMenu: boolean;
    foodOptions: FoodOptions;
    eatingOptions: OnlineEatingPreference[];
    webVersion: string;
    onStartOver: () => void;
    onScrollAndStartOver: () => void;
    isCompanyLanding: boolean;
    setHeaderOpen: (value: React.SetStateAction<boolean>) => void;
    handleEatingOptionClick: (eatingSelection: OnlineEatingPreference) => void;
    expressOnlineDisplaySettings: ExpressOnlineSettingsType;
    setExpressOnlineDisplaySettings: (settings: ExpressOnlineSettingsType) => void;
} & CommonProps;

type OpenProps = {
    shopIsClosed: boolean;
    hasActiveMenu: boolean;
    eatingOptions: OnlineEatingPreference[];
    webVersion: string;
    setHeaderOpen: (value: React.SetStateAction<boolean>) => void;
    handleEatingOptionClick: (eatingSelection: OnlineEatingPreference) => void;
    expressOnlineDisplaySettings: ExpressOnlineSettingsType;
    setExpressOnlineDisplaySettings: (settings: ExpressOnlineSettingsType) => void;
} & CommonProps;

const Open: React.FC<OpenProps> = ({
    selectedShop,
    handleEatingOptionClick,
    setHeaderOpen,
    shopIsClosed,
    expressOnlineDisplaySettings,
    setExpressOnlineDisplaySettings,
    webVersion,
    hasActiveMenu
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [click, setClick] = useState(1);
    const { translate } = useLanguage();

    const onToggleOpenModal = () => {
        if (!openModal) {
            setClick(click + 1);
            click === 4 && setOpenModal(!openModal);
        } else {
            setOpenModal(!openModal);
            setClick(1);
        }
    };
    const handleUpdateLocalStorage = (value: any) => {
        localStorage.setItem(LOCAL_STORAGE_CONSTANTS.EXPRESS_ONLINE_DISPLAY_SETTINGS, JSON.stringify(value));
        setExpressOnlineDisplaySettings(value);
    };
    const resetClick = () => {
        setClick(1);
    };
    const { type } = useOrientation();
    const isPortrait = type === "portrait-primary" || type === "portrait-secondary";

    return (
        <RemoveScroll removeScrollBar allowPinchZoom>
            {click > 1 && <Timer intervalTime={1500} doFunction={resetClick} runFirstMount={false} />}
            {openModal && (
                <ExpressOnlineSecretModal
                    webVersion={webVersion}
                    expressOnlineDisplaySettings={expressOnlineDisplaySettings}
                    onToggleOpenModal={onToggleOpenModal}
                    handleUpdateLocalStorage={handleUpdateLocalStorage}
                />
            )}
            <Flex
                flexDirection="column"
                width="100%"
                justifyContent="center"
                alignItems="center"
                height={isPortrait ? "90vh" : "80vh"}
                color={"black"}
            >
                <Flex flex="1" zIndex={100} justify={isPortrait ? "space-between" : "flex-start"} direction="column">
                    <Flex
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        zIndex={1}
                        position="relative"
                        className="animate-online-express-header-open-logo"
                        height={isPortrait ? { sm: "40vh", lg: "40vh" } : { md: "40vh", "2xl": "33vh" }}
                        mt={4}
                    >
                        <Image
                            src={selectedShop?.imageUrl}
                            maxWidth={isPortrait ? { sm: "320px", lg: "390px" } : { md: "260px", "2xl": "370px" }}
                            minWidth={"210px"}
                            height={"auto"}
                        />
                    </Flex>
                    <Flex
                        zIndex={1}
                        justifyContent="space-around"
                        alignItems="center"
                        width="100%"
                        height="auto"
                        position="relative"
                        className="animate-online-express-header-open-icons"
                    >
                        {shopIsClosed || !hasActiveMenu ? (
                            <Box
                                color={expressOnlineDisplaySettings.lightMode || !isPortrait ? "#405349" : "white"}
                                textAlign={"center"}
                                fontSize={
                                    isPortrait ? { sm: "3rem", lg: "3.5rem" } : { md: "1.75rem", "2xl": "2.25rem" }
                                }
                                mt={isPortrait ? 0 : "5rem"}
                            >
                                {translate("expressPOSIsClosed")}
                            </Box>
                        ) : (
                            <ExpressEatingOptions
                                lightMode={expressOnlineDisplaySettings.lightMode}
                                onEatingOptionClick={handleEatingOptionClick}
                                setHeaderOpen={setHeaderOpen}
                                eatingOptions={expressOnlineDisplaySettings.eatingOptions ?? []}
                                isPortrait={isPortrait}
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex
                    h="auto"
                    zIndex={100}
                    justify="center"
                    alignItems="center"
                    direction="row"
                    align="center"
                    mb={isPortrait ? 0 : 4}
                    mt={isPortrait ? 10 : 8}
                    position="relative"
                    filter={isPortrait ? "drop-shadow(2px 4px 6px black)" : "none"}
                >
                    <Image
                        src={SwishLogoPng}
                        onClick={() => onToggleOpenModal()}
                        height={isPortrait ? { sm: "35px", lg: "50px" } : { md: "25px", "2xl": "30px" }}
                        width={isPortrait ? { sm: "35px", lg: "50px" } : { md: "25px", "2xl": "30px" }}
                        mr={2}
                    />
                    <LanguageText
                        tid="onlyAccSwish"
                        fontSize={isPortrait ? { sm: "1.5rem", lg: "2.5rem" } : { md: "1.2rem", "2xl": "1.7rem" }}
                        color={isPortrait ? "white" : "black"}
                    />
                </Flex>
                {!isPortrait && (
                    <PoweredByQoplaLogo
                        logoStyles={{ fontSize: { sm: "1.5rem", lg: "2.6rem" } }}
                        textStyles={{ fontSize: { sm: "0.35rem", lg: "0.6rem" } }}
                    />
                )}
            </Flex>
        </RemoveScroll>
    );
};

type ClosedProps = {
    foodOptions: FoodOptions;
    onStartOver: () => void;
    onScrollAndStartOver: () => void;
    isCompanyLanding?: boolean;
} & CommonProps;

const Closed: React.FC<ClosedProps> = ({
    foodOptions,
    selectedShop,
    onStartOver,
    onScrollAndStartOver: onScrollAndStartOver,
    isCompanyLanding
}) => {
    const { translate, userLanguage } = useLanguage();
    !isCompanyLanding &&
        useOnlineExpressIdleTimer(userLanguage, {
            onClose: onScrollAndStartOver
        });

    const { text: eatingOptionText } = onlineEatingPreferencesIcons[foodOptions.eatingPreference];

    return (
        <Flex userSelect="none" zIndex={1} width="100%" height="100px" px={3}>
            <Flex
                justify="space-between"
                align="center"
                width="100%"
                className="animate-online-express-header-closed-icons"
            >
                <Button
                    borderWidth="3px"
                    size="xl"
                    fontSize="2xl"
                    onClick={onStartOver}
                    backgroundColor="white"
                    color="gray.800"
                    rightIcon={VscDebugRestart}
                >
                    {translate("startOver")}
                </Button>

                <Flex
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    className="animate-online-express-header-closed-logo"
                >
                    <Header m={0} ml={4} width={["min-content", "auto", "auto", "auto"]} display={["hidden", "block"]}>
                        {selectedShop?.name}
                    </Header>
                </Flex>
                <Flex
                    alignItems="center"
                    justifyContent={"center"}
                    position="relative"
                    direction="column"
                    className="animate-online-express-header-closed-icons"
                >
                    <Text mb={0}>{translate("youreOrdering")}</Text>
                    <Box as="h3" fontSize="3xl" m={0} ml={2} textTransform="uppercase">
                        {translate(eatingOptionText as Languagekey)}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const ExpressOnlineOrderHeader: React.FC<Props> = ({
    shopIsClosed,
    headerOpen,
    foodOptions,
    onStartOver,
    onScrollAndStartOver,
    expressOnlineDisplaySettings,
    setExpressOnlineDisplaySettings,
    isCompanyLanding,
    hasActiveMenu,
    ...rest
}) => {
    if (headerOpen) {
        return (
            <Open
                expressOnlineDisplaySettings={expressOnlineDisplaySettings}
                setExpressOnlineDisplaySettings={setExpressOnlineDisplaySettings}
                shopIsClosed={shopIsClosed}
                hasActiveMenu={hasActiveMenu}
                {...rest}
            />
        );
    } else {
        return (
            <Closed
                foodOptions={foodOptions}
                onStartOver={onStartOver}
                onScrollAndStartOver={onScrollAndStartOver}
                isCompanyLanding={!!isCompanyLanding}
                {...rest}
            />
        );
    }
};

import React from "react";

import { Flex } from "Atoms";
import { useOnline, EatingPreference } from "Providers";
import { ExpressOnlinePickupButton } from "../..";
import { OnlineEatingPreference } from "Types";

type Props = {
    handleEatingOptionClick: (eatingSelection: OnlineEatingPreference) => void;
    eatingOptions: OnlineEatingPreference[];
};

export const ExpressOnlineOrderStickyCartHeader: React.FC<Props> = ({ handleEatingOptionClick, eatingOptions }) => {
    const {
        foodOptions: { eatingPreference }
    } = useOnline();

    const isTakeAway = eatingPreference === EatingPreference.TAKE_AWAY;
    const showEatingOptions =
        eatingOptions.filter(
            (option: string) => option === EatingPreference.EAT_HERE || option === EatingPreference.TAKE_AWAY
        ).length > 1;

    return (
        <Flex justify="space-between" width="100%" direction="column" p={4}>
            <Flex justify="flex-end" align="center">
                {showEatingOptions && (
                    <ExpressOnlinePickupButton
                        handleSetFoodOptions={handleEatingOptionClick}
                        isTakeAway={isTakeAway}
                        eatingOptions={eatingOptions}
                        eatingPreference={eatingPreference}
                    />
                )}
            </Flex>
        </Flex>
    );
};

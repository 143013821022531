import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BaseBoxProps, Box, Text } from "Atoms";
import { GiftCard } from "Types";
import { useLanguage } from "Providers";
import { ShopList } from "./ShopList";
import { useMothershipLazyQuery } from "Hooks";
import { GET_PUBLIC_SHOP_INFOS_BY_IDS, GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID } from "GraphQLQueries";
import { formatShopUrlName } from "Utils";

type Props = {
    giftCardSettings: GiftCard;
    width?: string;
    isClickable?: boolean;
    redirectOnSingleShop?: boolean;
};

export const IncludedShops: React.FC<Props & BaseBoxProps> = ({
    giftCardSettings,
    isClickable = true,
    redirectOnSingleShop = false,
    ...rest
}) => {
    const { translate } = useLanguage();
    const navigate = useNavigate();

    const [loadShopInfosByIds, { data: publicShopsResults }] = useMothershipLazyQuery(GET_PUBLIC_SHOP_INFOS_BY_IDS);
    const [loadShopInfosByCompanyId, { data: shopInfosByCompanyId }] = useMothershipLazyQuery(
        GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID
    );

    const publicShops = giftCardSettings?.enabledForAllShops
        ? shopInfosByCompanyId?.getShopsPublicInfoByCompanyId
        : publicShopsResults?.getPublicShopInfosByIds;

    useEffect(() => {
        if (giftCardSettings?.enabledForAllShops) {
            loadShopInfosByCompanyId({
                variables: { companyId: giftCardSettings.companyId }
            });
        } else {
            loadShopInfosByIds({
                variables: { shopIds: giftCardSettings?.shopIds }
            });
        }
    }, [giftCardSettings]);

    if (publicShops && publicShops.length === 1 && redirectOnSingleShop) {
        const shop = publicShops[0];
        const to = `/restaurant/${formatShopUrlName(shop.name)}/${shop.publicId}/order`;
        navigate(to);
        return null;
    }

    return (
        <Box width={{ xs: "90%", md: "xs" }} mx="auto" pb={4} {...rest}>
            <Text mb={1} fontSize="xl">
                {translate("including")} {translate("restaurants")}:
            </Text>
            <ShopList publicShops={publicShops || []} isClickable={isClickable} />
        </Box>
    );
};

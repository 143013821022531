import React, { useEffect } from "react";
import { HiOutlineUserGroup } from "@react-icons/all-files/hi/HiOutlineUserGroup";
import { AiOutlineExclamation } from "@react-icons/all-files/ai/AiOutlineExclamation";
import { debounce } from "lodash";

import { Box, Collapsable, Flex, Header, Image, NewContainer, Stack, Text } from "Atoms";
import { OnlineOrderMenusNavigation } from "../../onlineOrder/components";
import { useQoplaStore, useTableStore } from "Stores";
import { useEventCallback, useSwitch } from "Hooks";
import { FullOrderWindowCustomer, modals, useModal } from "Providers";
import { GroupOnlineOrderCustomersStatuses } from "../../../shared/groupOrder/components";

type Props = {
    hasRequestToJoinCustomer: boolean;
    isInGroupOrder: boolean;
    customers: FullOrderWindowCustomer[];
    isLastToOrder: boolean;
};

export const GroupOnlineOrderHeader: React.FC<Props> = ({
    hasRequestToJoinCustomer,
    isInGroupOrder,
    customers,
    isLastToOrder
}) => {
    const { open, onClose, onOpen } = useSwitch(isInGroupOrder || true);

    const { selectedShop } = useQoplaStore();
    const { selectedTable } = useTableStore();
    const modalStore = useModal();

    const onOpenCustomersModal = () => {
        modalStore.openModal(modals.groupOnlineOrderCustomersModal);
    };

    /**
     * Effects below controls if we should expand the header using collapsable component
     * If customer is in group order, we will expand the collapsable and display
     * amount of ready / unready customers
     */
    useEffect(() => {
        if (isInGroupOrder) {
            onOpen();
        }
    }, [isInGroupOrder]);

    useEventCallback({
        eventName: "scroll",
        callback: debounce(() => {
            const scrollTop = window.scrollY;

            if (open && scrollTop > 60) {
                onClose();
            }
            if (!open && scrollTop === 0) {
                onOpen();
            }
        }, 10),
        active: !isInGroupOrder
    });

    return (
        <Box position="sticky" top="0" zIndex={20} bg="white">
            <Collapsable isOpen={open} height={60}>
                <Image src={selectedShop?.bannerUrl} maxH={24} objectFit="cover" filter="brightness(0.4)" />
                <NewContainer
                    bottom={0}
                    left={0}
                    top={0}
                    right={0}
                    maxH={24}
                    h="auto"
                    position="absolute"
                    w="full"
                    padding={4}
                    color="white"
                >
                    <Flex justify="space-between" align="center" h="auto">
                        <Header m={0} textTransform={"capitalize"}>
                            {selectedTable?.name}
                        </Header>
                        <Stack isInline align="center" stretch={2} onClick={onOpenCustomersModal}>
                            <Box position="relative">
                                <Box size="2.25rem" as={HiOutlineUserGroup} />
                                {hasRequestToJoinCustomer && (
                                    <Box
                                        as={AiOutlineExclamation}
                                        backgroundColor="blue.500"
                                        rounded="full"
                                        bottom="10px"
                                        right="0"
                                        h={5}
                                        w="5"
                                        transform="translate(25%, 25%)"
                                        position="absolute"
                                    />
                                )}
                            </Box>
                            <Text fontSize="3xl" fontWeight="semibold">
                                {customers.length}
                            </Text>
                        </Stack>
                    </Flex>
                    <GroupOnlineOrderCustomersStatuses
                        isInGroupOrder={isInGroupOrder}
                        isLastToOrder={isLastToOrder}
                        customers={customers}
                    />
                </NewContainer>
            </Collapsable>
            <NewContainer>
                <OnlineOrderMenusNavigation />
            </NewContainer>
        </Box>
    );
};

import React from "react";

import { Flex } from "Atoms";
import { OnlineButton } from "OnlineComponents";
import { OnlineProductCategories } from "Types";

type Props = {
    onlineProductCategories: OnlineProductCategories;
    activeMenuProdCatId: string;
    setActiveMenuProdCatId: any;
    small?: boolean;
};

export const ExpressOnlineOrderCategoriesNavigation: React.FC<Props> = ({
    onlineProductCategories,
    activeMenuProdCatId,
    setActiveMenuProdCatId,
    ...rest
}) => {
    const onMenuProdCatClick = (menuId: string, ref: React.RefObject<any>) => {
        setActiveMenuProdCatId(menuId);
        const node = ref.current;
        const currentNodeY = node.getBoundingClientRect().top - 76;
        node.scrollIntoView(true);
        const scrolledY = window.scrollY ?? 0;
        window.scroll(0, scrolledY + currentNodeY - 200);
    };

    return (
        <>
            {onlineProductCategories.map(({ name, id, ref }) => {
                const isActive = !rest.small && activeMenuProdCatId === id;
                return (
                    <Flex key={id} id={name} py={1} marginLeft={[5, 5, 0, 0, 0]} marginRight={[5, 5, 12, 6, 6]}>
                        <OnlineButton
                            variant="link"
                            rounded={8}
                            themeColor={isActive ? "green" : "gray"}
                            size={isActive ? "2xl" : "xl"}
                            textDecoration={isActive ? "underline" : "none"}
                            onClick={() => onMenuProdCatClick(id, ref)}
                        >
                            {name}
                        </OnlineButton>
                    </Flex>
                );
            })}
        </>
    );
};

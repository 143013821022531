import React from "react";
import Truncate from "react-truncate";
import { Dimmer } from "semantic-ui-react";

import { MenuProductCategory as MenuProductCategoryType } from "Types";
import { Flex, Text, Header, Box, Button, NewGrid as Grid } from "Atoms";
import { useLanguage, useQopla, useOnline } from "Providers";
import { OnlineHeader } from "OnlineComponents";
import { isMenuBundleProduct } from "Utils";
import { expressStartMenuItemType } from "Constants";
import { SetOnlineProductModalState } from "OnlineTypes";
import { dimExpress } from "OnlineUtils";
import { useOrderWindowsStore } from "Stores";

export interface BaseMenuProductCategoriesProps {
    outOfStockIds: Set<string>;
    hideFromStockIds: Set<string>;
    productsIdsAndQuantity: { [key: string]: number };
    toggleProductModal: SetOnlineProductModalState;
    activeMenuProdCatId?: string;
    activeMenuProductCategories?: MenuProductCategoryType[];
}

interface Props extends BaseMenuProductCategoriesProps {
    menuItems: any;
    menu: any;
    setActiveMenuProdCatId: any;
    isProductCommentsEnabled?: boolean;
    isPortrait: boolean;
}

export const ExpressOnlineOrderMenuItems: React.FC<Props> = ({
    menuItems,
    menu,
    setActiveMenuProdCatId,
    isProductCommentsEnabled,
    toggleProductModal,
    outOfStockIds,
    hideFromStockIds,
    productsIdsAndQuantity,
    isPortrait
}) => {
    const { translate } = useLanguage();
    const { addBundleProductToCart, setSelectedBundleCartProduct } = useOrderWindowsStore();
    const { selectedShop } = useQopla();

    const handleItemClick = (product: any) => {
        const menuId = product.id;

        if (product.itemType === expressStartMenuItemType["MENUCATEGORY"]) {
            const node = product.ref.current;

            node.scrollIntoView(true);

            const scrolledY = window.scrollY;

            if (scrolledY) {
                window.scroll(9, scrollY - 128);
            }

            setActiveMenuProdCatId(menuId);
        } else {
            openRestaurantProductModal(product);
        }
    };

    const openRestaurantProductModal = (product: any) => {
        if (product.isOutOfStock) return;

        if (isMenuBundleProduct(product) && !!selectedShop) {
            const cartProduct = addBundleProductToCart(product, selectedShop?.id, product.menuProductCategoryId, false);
            setSelectedBundleCartProduct(cartProduct);
        }
        // @ts-ignore
        toggleProductModal({
            onlineProduct: product,
            menuProductCategoryId: product.menuProductCategoryId,
            shopId: selectedShop?.id,
            isUpsell: true,
            isProductCommentsEnabled: !!isProductCommentsEnabled
        });
    };

    const getProductStatuses = (
        allItems: any,
        outOfStockIds: Set<string>,
        productsIdsAndQuantity: { [key: string]: number }
    ) => {
        return allItems.map((menuItem: any) => {
            const prod = "refBundleProduct" in menuItem ? menuItem.refBundleProduct : menuItem.refProduct;
            if (!prod) return menuItem; // Skip categories
            const isOutOfStock = outOfStockIds && outOfStockIds.has(prod.id);
            const productQuantity = productsIdsAndQuantity[prod.id];
            return {
                ...menuItem,
                isOutOfStock,
                productQuantity
            };
        });
    };

    const menuItemsWithStatus = getProductStatuses(menuItems, outOfStockIds, productsIdsAndQuantity);

    const filteredMenuItems = menuItemsWithStatus.filter((menuProduct: any, idx: number) => {
        if (idx < 6) {
            if (!menuProduct.refBundleProduct && !menuProduct.refProduct) return true; // Skip categories
            return hideFromStockIds
                ? !hideFromStockIds.has(
                      //@ts-ignore
                      (menuProduct.refBundleProduct || menuProduct.refProduct).id
                  )
                : true;
        }
    });

    const { browseMode } = useOnline();

    return (
        <Grid
            area="startmenu"
            paddingLeft={[0, 0, 0, 12, 12, 24]}
            paddingTop={6}
            paddingBottom={[0, 0, 0, 12, 12, 12]}
            paddingRight={[0, 0, 0, 12, 12, 12]}
            background="#E2E8F0"
        >
            <Flex padding={1} marginBottom={3} alignItems="center">
                <Flex flex="1" alignItems="center" flexDirection="column">
                    <OnlineHeader as="h2" fontSize="42px" fontWeight="normal" margin={0}>
                        {menu.header}
                    </OnlineHeader>
                    {menu.description && (
                        <>
                            <Text textAlign="left" fontSize="18px">
                                {menu.description}
                            </Text>
                        </>
                    )}
                </Flex>
            </Flex>
            <div className={isPortrait ? "online-order-card-grid-portrait" : "online-order-card-grid"}>
                {filteredMenuItems.map((product: any, index: number) => {
                    //prettier-ignore
                    const productName = product.name ? product.name
                            : product.refProduct ? product.refProduct.name
                            : product.refBundleProduct ? product.refBundleProduct.name
                            : "";

                    const disabled = [dimExpress(browseMode), product.isOutOfStock].some(d => d);

                    const pseudoStyles = {
                        transform: disabled ? "" : ["", "", "", "scale(1.01)"],
                        boxShadow: disabled
                            ? ""
                            : ["", "", "", "0 8px 12px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"]
                    };
                    return (
                        <Flex key={index} height="auto" width="100%" minWidth="100%">
                            <Dimmer.Dimmable
                                dimmed={product.isOutOfStock}
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Button
                                    as="button"
                                    onClick={() => handleItemClick(product)}
                                    isDisabled={disabled}
                                    position="relative"
                                    border="none"
                                    padding={4}
                                    paddingBottom={2}
                                    height="290px"
                                    maxHeight="290px"
                                    fontWeight={400}
                                    borderRadius="0.75rem"
                                    width="100%"
                                    whiteSpace="pre-wrap"
                                    backgroundColor="#fff"
                                    cursor={disabled ? "not-allowed" : "pointer"}
                                    overflow="hidden"
                                    boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                                    transition="all 0.2s cubic-bezier(0.44, 2.1, 0.04, 1.65)"
                                    backgroundImage={`url("${product.imageUrl}")`}
                                    backgroundSize="cover"
                                    backgroundPosition="center"
                                    backgroundRepeat="no-repeat"
                                    _hover={pseudoStyles}
                                    _active={pseudoStyles}
                                >
                                    <Flex width="100%" textAlign="left" position="absolute" bottom="0" height="100px">
                                        <Flex
                                            width="100%"
                                            background="linear-gradient(rgba(0,0,0,0), rgb(0,0,0,1) 80%)"
                                            alignItems="center"
                                            padding="10px 20px"
                                        >
                                            <Box width="100%" textAlign="center">
                                                <Header
                                                    size="md"
                                                    as="h4"
                                                    margin="0"
                                                    wordBreak="break-word"
                                                    color="#fff"
                                                    fontSize="2rem"
                                                >
                                                    {/** @ts-ignore */}
                                                    <Truncate lines={2} ellipsis={<span>...</span>}>
                                                        {productName}
                                                    </Truncate>
                                                </Header>
                                            </Box>
                                            <Text wordBreak="break-word">
                                                {/** @ts-ignore */}
                                                <Truncate lines={3}>{product.description}</Truncate>
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Button>
                                <Dimmer className="menu-card-dimmer" active={product.isOutOfStock}>
                                    <Header size="md" as="h3" margin="0" color="#FFF" wordBreak="break-word">
                                        {productName} {translate("outOfStock")}
                                    </Header>
                                </Dimmer>
                            </Dimmer.Dimmable>
                        </Flex>
                    );
                })}
            </div>
        </Grid>
    );
};

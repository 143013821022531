import React from "react";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";
import { MdClose } from "@react-icons/all-files/md/MdClose";

import { Modal, ModalHeader, ModalBody, ModalActions } from "Molecules";
import { Button, Stack, Text, Flex, NewDivider, Box } from "Atoms";
import { useOrderWindowSocketStore, useOrderWindowsStore } from "Stores";
import { useLanguage } from "Providers";
import { getCleanGroupOrderNickname } from "../../../../customer/shared/groupOrder/utils";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export const GroupOnlineOrderInviteModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const { nickname, onJoinGroupOrder, groupOrderNicknames } = useOrderWindowSocketStore();
    const { orderWindows, getActiveOrderWindow } = useOrderWindowsStore();
    const { translate } = useLanguage();

    const orderWindow = getActiveOrderWindow();

    const cleanGroupOrderNicknames = groupOrderNicknames?.map(customer => getCleanGroupOrderNickname(customer)) ?? [];

    return (
        <Modal isScrolling open={isOpen} onClose={onClose}>
            <ModalHeader>{translate("groupOrderInviteTitle")}</ModalHeader>
            <ModalBody pt={0}>
                <Text fontSize="lg" fontWeight={700} mb={2}>
                    {translate("groupOrderInviteBody")}
                </Text>
                <Stack stretch={3}>
                    {cleanGroupOrderNicknames.map(nickname => (
                        <Text key={nickname} fontSize="lg">
                            {nickname}
                        </Text>
                    ))}
                </Stack>
            </ModalBody>
            <ModalActions>
                <Flex justify="space-evenly" align="center">
                    <Button
                        onClick={onClose}
                        flexDirection="column"
                        size="xl"
                        background="transparent"
                        rounded="lg"
                        h="auto"
                        flex="1"
                        variant="ghost"
                        themeColor="red"
                    >
                        <Box as={MdClose} size="3rem" color="red.500" />
                        <Text as="span" mt={2} color="red.500">
                            {translate("no")}
                        </Text>
                    </Button>
                    <NewDivider rotate="90deg" w="30px" borderWidth={2} borderColor="gray.500" />
                    <Button
                        onClick={() => {
                            onJoinGroupOrder(nickname as string);
                            onClose();
                        }}
                        flexDirection="column"
                        size="xl"
                        background="transparent"
                        rounded="lg"
                        h="auto"
                        flex="1"
                        variant="ghost"
                        themeColor="green"
                    >
                        <Box as={FiCheck} size="3rem" color="green.500" />
                        <Text as="span" mt={2} color="green.500">
                            {translate("yes")}
                        </Text>
                    </Button>
                </Flex>
            </ModalActions>
        </Modal>
    );
};

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";

import { getCartTotalPrice, isCartFinished, getExpressOnlineCheckoutUrl } from "Utils";
import { Languagekey, getCartProductsQuantity } from "Providers";
import { LoadingMessage, roundNumber } from "TempUtils";
import { useOnline, useLanguage } from "Providers";
import { OrderWindow } from "Types";
import { Box, Flex, Header, Text, NewDivider, NewGrid as Grid } from "Atoms";
import { useShouldClearOnlineCart } from "../../../pages/onlineOrder/hooks";
import { CloseButton, LinkButton } from "Molecules";
import { getOnlineBrowseOnlyInfo, BrowseModeMessage } from "OnlineUtils";
import { onlineEatingPreferencesIcons } from "OnlineUtils";
import { SetOnlineProductModalState } from "OnlineTypes";
import { OnlineCustomerCartList } from "OnlineComponents";
import { OnlineUpsellModal } from "OnlineComponents";
import { useOrderWindowsStore } from "Stores";

type ClosedProps = {
    onOpenCart: () => void;
    totalPrice: number;
    openPickupModal: () => void;
    browseInfo: BrowseModeMessage | undefined;
    hasActiveMenus: boolean;
    cartProductsAmount: number;
};

const Closed: React.FC<ClosedProps> = ({
    onOpenCart,
    totalPrice,
    openPickupModal,
    browseInfo,
    hasActiveMenus,
    cartProductsAmount
}) => {
    const { translate } = useLanguage();

    const hasBrowseMessage = browseInfo !== undefined && browseInfo.textKey !== "rushHourText" && hasActiveMenus;

    return (
        <Box right="0" bottom="0" left="0" px={3} py={5} onClick={onOpenCart}>
            <Grid
                templateColumns="1fr 2fr 1fr"
                templateAreas="'none see-order price'"
                alignItems="center"
                bg={!!totalPrice && !hasBrowseMessage ? "newPrimary" : "gray.700"}
                color={!!totalPrice && !hasBrowseMessage ? "newPrimaryFont" : "white"}
                rounded="lg"
                p={3}
                boxShadow="0 4px 6px -1px rgba(0,0,0,0.25), 0 2px 4px -1px rgba(0,0,0,0.075)"
                position={"fixed"}
                bottom={"2rem"}
                left={"15px"}
                width="96%"
            >
                <Box justifySelf="center" gridArea="see-order">
                    <Text
                        as="span"
                        color="inherit"
                        fontSize="3xl"
                        ml={hasBrowseMessage ? 4 : undefined}
                        whiteSpace={"nowrap"}
                    >
                        {hasBrowseMessage ? translate(browseInfo.titleKey as Languagekey) : translate("viewOrder")}
                    </Text>
                </Box>
                <Box justifySelf="flex-end" gridArea="price">
                    {!hasBrowseMessage && (
                        <>
                            {cartProductsAmount > 0 && (
                                <Text as="span" color="inherit" fontSize="3xl">
                                    {`${cartProductsAmount} ${translate("st")}  |  `}
                                </Text>
                            )}
                            <Text as="span" color="inherit" fontSize="3xl">
                                {totalPrice} kr
                            </Text>
                        </>
                    )}
                </Box>
            </Grid>
        </Box>
    );
};

type OpenProps = {
    setExpandMobileCart: React.Dispatch<React.SetStateAction<boolean>>;
    orderWindow: OrderWindow;
    isProductCommentsEnabled?: boolean;
    params: {
        name: string;
        id: string;
    };
    subTotal: number;
};

const Open: React.FC<OpenProps> = ({ params, setExpandMobileCart, orderWindow, isProductCommentsEnabled }) => {
    const { foodOptions } = useOnline();
    const { icon, text } = onlineEatingPreferencesIcons[foodOptions.eatingPreference];
    const { translate } = useLanguage();

    const isCheckoutDisabled = !orderWindow?.cartProducts?.length || !isCartFinished(orderWindow?.cartProducts);

    return (
        <Flex
            direction="column"
            width="100%"
            bg="white"
            height="40vh"
            zIndex={10}
            position={"fixed"}
            bottom="1rem"
            rounded="lg"
            borderTop="2px solid #FAFAFA"
            boxShadow="0 4px 20px 4px rgba(0,0,0,0.5), 0 2px 4px -1px rgba(0,0,0,0.05)"
        >
            <Flex flex="0 1 auto" height="auto" p={4}>
                <Header m="0">{translate("yourOrder")}</Header>
                {/* @ts-ignore */}
                <CloseButton closeButtonIcon={IoIosArrowDown} onClick={() => setExpandMobileCart(c => !c)} />
            </Flex>
            <Flex px={4} height="auto">
                <Flex color="gray.700" mr={4}>
                    {icon && <Box as={icon} color="inherit" size={8} mr={1} />}
                    <Text as="span" color="inherit" fontSize="2xl">
                        {translate(text as Languagekey)}
                    </Text>
                </Flex>
            </Flex>
            <NewDivider color="gray.300" />
            <Flex flex="1 1 auto" overflow="auto" mt={2}>
                {orderWindow?.cartProducts?.length > 0 && (
                    <OnlineCustomerCartList
                        appliedDiscount={orderWindow.discount}
                        isProductCommentsEnabled={isProductCommentsEnabled}
                    />
                )}
            </Flex>
            <Text px={"1rem"} pt={"2rem"} color="gray.600" textAlign={"center"}>
                {translate("allergyText")}
            </Text>
            <Flex
                flex="0 1 60px"
                px={3}
                py={5}
                boxShadow="0 0 15px rgba(0,0,0,0.1)"
                borderTop="2px solid #CBD5E0"
                position="sticky"
            >
                <LinkButton
                    height="auto"
                    bg="newPrimary"
                    color="newPrimaryFont"
                    fullWidth
                    _hover={{}}
                    lineHeight="1.7"
                    size="lg"
                    isDisabled={isCheckoutDisabled}
                    to={`/express/restaurant/${params.name}/${params.id}/checkout`}
                    rounded="lg"
                    p={3}
                >
                    <Flex alignItems={"center"}>
                        <Text as="span" fontSize="3xl" fontWeight="normal" mr={4}>
                            {translate("toCheckout")}
                        </Text>
                        <Box rounded="full" bg="newPrimaryFont" h="2.8rem" w="2.8rem">
                            <Box as={FaArrowRight} size="1.5rem" color="newPrimary" mt="0.7rem" />
                        </Box>
                    </Flex>
                </LinkButton>
            </Flex>
        </Flex>
    );
};

type Props = {
    expandMobileCart: boolean;
    setExpandMobileCart: React.Dispatch<React.SetStateAction<boolean>>;
    openPickupModal: () => void;
    toggleProductModal?: SetOnlineProductModalState;
    upsellCategory: any;
    shopId: string;
    outOfStockIds: any;
    productsIdsAndQuantity: any;
    isProductCommentsEnabled?: boolean;
    hasActiveMenus: boolean;
    setUpsellModalState: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            hasShown: boolean;
        }>
    >;
    upsellModalState: {
        open: boolean;
        hasShown: boolean;
    };
    isCompanyLanding: boolean;
    subdomainName: string;
};

type Params = { name: string; id: string };

const ExpressOnlineStickyMobileCart: React.FC<Props> = ({
    expandMobileCart,
    setExpandMobileCart,
    openPickupModal,
    isProductCommentsEnabled,
    hasActiveMenus,
    upsellModalState,
    setUpsellModalState,
    isCompanyLanding,
    subdomainName,
    ...rest
}) => {
    const params = useParams<Params>();
    const { browseMode } = useOnline();
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const navigate = useNavigate();

    const orderWindow = getActiveOrderWindow();

    const subTotal = roundNumber(getCartTotalPrice(orderWindow?.cartProducts));
    const totalPrice = subTotal;

    const cartProductsAmount = getCartProductsQuantity(orderWindow?.cartProducts);
    const browseInfo = getOnlineBrowseOnlyInfo(browseMode);
    useShouldClearOnlineCart();

    useEffect(() => {
        let id: number;
        if (rest.upsellCategory && expandMobileCart && !upsellModalState.hasShown) {
            id = window.setTimeout(() => {
                setUpsellModalState({ open: true, hasShown: true });
            }, 400);
        }
        return () => window.clearTimeout(id);
    }, [expandMobileCart]);

    const onOpenCart = () => {
        const shouldDisabel = browseInfo && browseInfo.titleKey !== "rushHourTitle";
        if (cartProductsAmount === 0 || shouldDisabel) return;
        setExpandMobileCart(!expandMobileCart);
    };

    if (!orderWindow) {
        return <LoadingMessage />;
    }

    const onUpsellModalContinuePress = () => {
        const expressCheckoutUrl = getExpressOnlineCheckoutUrl({
            shopName: params.name!,
            shopId: params.id!,
            isCompanyLanding,
            subdomainName
        });
        navigate(expressCheckoutUrl);
    };

    if (expandMobileCart) {
        return (
            <>
                <Open
                    params={params as Params}
                    isProductCommentsEnabled={isProductCommentsEnabled}
                    setExpandMobileCart={setExpandMobileCart}
                    orderWindow={orderWindow}
                    subTotal={subTotal}
                />
                {upsellModalState.open && (
                    <OnlineUpsellModal
                        {...rest}
                        onClose={() => setUpsellModalState({ hasShown: true, open: false })}
                        onContinuePress={onUpsellModalContinuePress}
                    />
                )}
            </>
        );
    } else {
        return (
            <Closed
                onOpenCart={onOpenCart}
                openPickupModal={openPickupModal}
                totalPrice={totalPrice}
                browseInfo={browseInfo}
                hasActiveMenus={hasActiveMenus}
                cartProductsAmount={cartProductsAmount}
            />
        );
    }
};

export default ExpressOnlineStickyMobileCart;

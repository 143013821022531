import React from "react";

import { Box, Center, Flex, Header, Spinner, Stack, Text } from "Atoms";
import { formatFinancialNumbers } from "PriceUtils";
import { useLanguage } from "Providers";
import { OnlineAddonsList } from "Organisms";
import { CustomerModificationsList } from "../../orderStatus/components";
import { CartProduct, OrderWindow } from "Types";
import { SwishOrderResponse } from "../../orderStatus/OrderStatusContainer";
import { Alert, AlertBody, AlertIcon, AlertTitle } from "Molecules";
import { getCleanGroupOrderNickname } from "../../../../customer/shared/groupOrder/utils";
import { useQoplaStore } from "Stores";

type CustomerPaidByMe = {
    nickname: string;
    totalAmountPaid: number;
    cartProducts: CartProduct[];
};

type Props = {
    order: SwishOrderResponse;
    orderWindow: OrderWindow | null;
    nickname: string;
};

export const GroupOnlineOrderConfirmationSummary: React.FC<Props> = ({ order, orderWindow, nickname }) => {
    const { translate, translateWithArgument } = useLanguage();
    const { companyLocale } = useQoplaStore();

    if (!orderWindow) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }

    const nicknamesPaidByMe =
        orderWindow?.customers?.filter(customer => customer?.paidBy === nickname).map(customer => customer.nickname) ??
        [];

    const customersIPaidFor = Array.from(nicknamesPaidByMe).map(nickname => {
        const productsAddedByCustomer = orderWindow.cartProducts.filter(
            cartProduct => cartProduct.customerMeta?.addedBy === nickname
        );
        const totalAmountPaid = productsAddedByCustomer.reduce((total, cp) => total + cp.orderProduct.totalPrice, 0);

        return {
            nickname: nickname ?? "",
            cartProducts: productsAddedByCustomer,
            totalAmountPaid
        };
    }) as CustomerPaidByMe[];

    const me = orderWindow.customers?.find(customer => customer.nickname === nickname);
    const isPaidFor = me?.nickname !== me?.paidBy;

    const cleanPayingCustomerNickname = isPaidFor ? getCleanGroupOrderNickname(me?.paidBy ?? "") : "";

    customersIPaidFor.sort(a => (a.nickname === nickname ? -1 : 1));

    return (
        <Flex direction="column">
            <Alert
                mb={8}
                status="success"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                rounded="lg"
            >
                <AlertIcon size="40px" mr={0} />
                <AlertTitle fontSize="xl" color="gray.800" mt={4} mr={0}>
                    {isPaidFor && cleanPayingCustomerNickname
                        ? translateWithArgument("yourOrderIsPaidBy", cleanPayingCustomerNickname)
                        : translate("yourOrderIsPaid")}
                </AlertTitle>
                {isPaidFor && cleanPayingCustomerNickname && (
                    <AlertBody mt={1} display="flex">
                        <Center direction="column" color="gray.800" fontSize="lg">
                            <Text as="span">{translate("yourOrderHasBeenPaid")}</Text>
                        </Center>
                    </AlertBody>
                )}
            </Alert>
            <Stack flex="1" stretch={8}>
                {customersIPaidFor.map(paidCustomer => {
                    return (
                        <Box key={paidCustomer.nickname}>
                            <Flex justify="space-between" color="gray.900" h="auto" mb={3}>
                                <Header as="h3" size="md" m="0" textTransform="capitalize">
                                    {nickname === paidCustomer.nickname
                                        ? translate("yourProducts")
                                        : `${getCleanGroupOrderNickname(paidCustomer.nickname)} ${translate(
                                              "products"
                                          )}`}
                                </Header>
                                <Header as="h3" size="md" m="0">
                                    {formatFinancialNumbers(paidCustomer.totalAmountPaid, companyLocale)}
                                </Header>
                            </Flex>
                            <Stack stretch={2}>
                                {paidCustomer.cartProducts.map(cartProduct => {
                                    const { orderProduct } = cartProduct;

                                    return (
                                        <Box key={cartProduct.id}>
                                            <Flex h="auto" justify="space-between" color="gray.900">
                                                <Text fontSize="lg" color="gray.900" m="0">
                                                    {orderProduct.quantity}st {orderProduct.name}
                                                </Text>
                                                <Text fontSize="lg" color="gray.900" m="0">
                                                    {orderProduct.totalPrice !== 0
                                                        ? formatFinancialNumbers(orderProduct.totalPrice, companyLocale)
                                                        : translate("free")}
                                                </Text>
                                            </Flex>
                                            <OnlineAddonsList mb={2} addons={orderProduct.addons} />
                                            {!!orderProduct.modifications && (
                                                <CustomerModificationsList
                                                    modifications={orderProduct.modifications}
                                                    productPrice={orderProduct.totalPrice}
                                                />
                                            )}
                                            {orderProduct.comment && (
                                                <>
                                                    <Text margin={0} fontWeight="bold">
                                                        {translate("comment")}:
                                                    </Text>
                                                    <Box
                                                        backgroundColor="gray.100"
                                                        rounded={3}
                                                        padding={4}
                                                        marginY={3}
                                                        wordBreak="break-word"
                                                    >
                                                        <Text>{orderProduct.comment}</Text>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </Box>
                    );
                })}
            </Stack>
            {!isPaidFor && (
                <Flex as="footer" align="flex-end" direction="column" height="auto">
                    <Stack stretch={4} align="flex-end" w="full" bg="gray.100" rounded="lg" p={4} mt={8}>
                        {!!order.tip && (
                            <Flex w="100%" justify="space-between" fontSize="lg">
                                <Text as="span" color="gray.700">
                                    {translate("tip")}
                                </Text>
                                <Text as="span">{formatFinancialNumbers(order.tip, companyLocale)}</Text>
                            </Flex>
                        )}
                        {order.vatRatesAndAmounts.map(
                            ({ vatRate, amount }) =>
                                amount !== 0 && (
                                    <Flex key={amount} w="100%" justify="space-between" fontSize="lg">
                                        <Text as="span" color="gray.700">
                                            {translate("vat")} {vatRate}%
                                        </Text>
                                        <Text as="span">{formatFinancialNumbers(amount, companyLocale)}</Text>
                                    </Flex>
                                )
                        )}

                        <Flex w="100%" justify="space-between">
                            <Text as="span" fontSize="xl" lineHeight="1.2" fontWeight="bold">
                                {translate("total")}
                            </Text>
                            <Text as="span" fontSize="xl" lineHeight="1.2" fontWeight="bold">
                                {formatFinancialNumbers(order.totalAmount, companyLocale)}
                            </Text>
                        </Flex>
                    </Stack>
                </Flex>
            )}
        </Flex>
    );
};

import React from "react";
import queryString from "query-string";
import AnimateHeight from "react-animate-height";
import { useMedia } from "react-media";
import styled, { keyframes } from "styled-components";

import { usePingBackend, useOrientation, PingBackendState } from "Hooks";
import { useOnline, useLanguage } from "Providers";
import { Box, Image, Flex, Text } from "Atoms";
import { ExpressOnlineOrderHeader } from ".";
import { LanguagePicker } from "Components";
import { OnlineEatingPreference, OnlineSettings, Shop } from "Types";
import { useOnlineStore, useOrderWindowsStore, useQoplaStore } from "Stores";
import { GLOBAL_BREAK_POINTS } from "Constants";
import "../expressOnlineOrder.scss";
import { ExpressOnlineSettingsType } from "../../../utils";
import { PoweredByQoplaLogo } from "../../../shared/components";

const shineAnimation = keyframes`
    0% { 
        will-change: opacity, background-position; 
        opacity: 0; 
        background-position: 100% 0;
    };
    40% {  
        opacity: 0.7;
        background-position: 100% 0; 
    };
    100% { 
        opacity: 1;
        background-position: 0 100%; 
    };
`;

const ShiningBoxAnimation = styled("div")`
    animation: 10s ${shineAnimation} ease-in-out infinite;
    background: linear-gradient(to top right, transparent 58%, rgb(255 254 254 / 58%) 60%, transparent 62%);
    background-size: 300% 500%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`;

type Props = {
    headerOpen: boolean;
    lightMode: boolean;
    onlineSettings: OnlineSettings;
    expressOnlineDisplaySettings: ExpressOnlineSettingsType;
    setExpressOnlineDisplaySettings: (value: ExpressOnlineSettingsType) => void;
    handleEatingOptionClick: (eatingSelection: OnlineEatingPreference) => void;
    setHeaderOpen: (value: React.SetStateAction<boolean>) => void;
    onStartOver: () => void;
    onScrollAndStartOver: () => void;
    refetchShopData: () => void;
    refetch: () => void;
};

export const ExpressOnlineStartScreen: React.FC<Props> = ({
    headerOpen,
    lightMode,
    onlineSettings,
    expressOnlineDisplaySettings,
    setExpressOnlineDisplaySettings,
    handleEatingOptionClick,
    setHeaderOpen,
    onStartOver,
    onScrollAndStartOver,
    refetchShopData,
    refetch
}) => {
    const { selectedShop } = useQoplaStore();
    const { translate } = useLanguage();
    const { foodOptions, browseMode } = useOnline();
    const { activeMenu } = useOnlineStore();
    const { activeMenus: posProviderActiveMenus } = useOrderWindowsStore();
    const urlParams = queryString.parse(location.search);
    const { clp } = urlParams;
    const isCompanyLanding = !!clp;

    let uniqueEatingOptions: OnlineEatingPreference[] = [];
    if (posProviderActiveMenus?.length > 0) {
        posProviderActiveMenus.forEach((menu: any) => {
            uniqueEatingOptions.push(...menu.eatingOptions);
        });
        uniqueEatingOptions = [...new Set(uniqueEatingOptions)];
    }

    const pingBackendState = usePingBackend({
        shopId: selectedShop?.id || null,
        companyId: selectedShop?.companyId || null,
        shopUpdateCallback: () => {
            refetchShopData();
            refetch();
        },
        webFrontendVersionCallback: () => location.reload()
    });

    const { type } = useOrientation();
    const isPortrait = type === "portrait-primary" || type === "portrait-secondary";

    const webFrontendVersion = (pingBackendState as PingBackendState)?.webFrontendVersion;

    const hasActiveMenu = (posProviderActiveMenus && posProviderActiveMenus.length > 0) || !!activeMenu;
    const isLargeScreen = useMedia({ query: GLOBAL_BREAK_POINTS.LG });

    //will use color from session storage(as we did before), if there is no color in settings
    let textColor = onlineSettings.expressOnlineTextColor;
    const activateAnimation = expressOnlineDisplaySettings.animation;

    //TODO remove when all shops will get color in shopSettings
    if (!textColor) {
        if (!!expressOnlineDisplaySettings?.color) {
            const { r, g, b, a } = expressOnlineDisplaySettings.color;
            textColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        } else {
            textColor = "whiteAlpha.900";
        }
    }

    return (
        <>
            {headerOpen && activateAnimation && <ShiningBoxAnimation />}
            <AnimateHeight height={headerOpen ? "auto" : 100} style={{ position: "relative" }}>
                <Box overflow="hidden" position="relative" height={"100vh"} width="100%" userSelect="none">
                    {lightMode && (
                        <Box
                            height="100vh"
                            backgroundColor="rgba(255, 255, 255, 0.8)"
                            width="100%"
                            zIndex={1}
                            position="absolute"
                        />
                    )}
                    <Box display={isPortrait || !headerOpen ? "block" : "flex"} position="relative">
                        <Box width={isPortrait || !headerOpen ? "100%" : "70%"}>
                            <Image
                                src={selectedShop?.bannerUrl}
                                position="absolute"
                                top="0"
                                m="auto"
                                width={isPortrait || !headerOpen ? "100%" : "70%"}
                                minHeight="100%"
                                filter="brightness(70%)"
                                zIndex={0}
                                objectFit={"cover"}
                                h="100vh"
                            />
                            {headerOpen && (
                                <Flex
                                    direction={"column"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    position={isPortrait ? "absolute" : "static"}
                                    top={isPortrait ? "40%" : undefined}
                                    left={isPortrait ? "10%" : undefined}
                                    h={isPortrait ? "auto" : "100vh"}
                                    zIndex={10}
                                >
                                    <Text
                                        letterSpacing="3px"
                                        fontSize={
                                            isPortrait ? { sm: "7rem", lg: "10rem" } : { md: "7rem", "2xl": "10rem" }
                                        }
                                        color={textColor}
                                        fontWeight="Bold"
                                        margin={0}
                                        zIndex={10}
                                        textShadow={"4px 9px 17px #272727eb"}
                                    >
                                        {translate("orderHere").toUpperCase()}
                                    </Text>
                                    <Text
                                        letterSpacing="2px"
                                        fontSize={
                                            isPortrait ? { sm: "2rem", lg: "2.8rem" } : { md: "2rem", "2xl": "4rem" }
                                        }
                                        color={textColor}
                                        fontWeight="Bold"
                                        margin={0}
                                        zIndex={10}
                                        textShadow={"4px 9px 17px #272727eb"}
                                    >
                                        {translate("expressOnlineStartScreenText")}
                                    </Text>
                                </Flex>
                            )}
                        </Box>
                        <Box
                            width={isPortrait || !headerOpen ? "100%" : "30%"}
                            bg={isPortrait || !headerOpen ? "none" : "white"}
                            position={"relative"}
                            zIndex={2}
                        >
                            {!isPortrait && headerOpen && (
                                <Box mt={1} mr={1}>
                                    <LanguagePicker
                                        size="lg"
                                        justifyContent={"flex-end"}
                                        hideSelected
                                        buttonThemeColor={"white"}
                                        mr={0}
                                    />
                                </Box>
                            )}
                            <Flex color={lightMode ? "#405349" : "white"} justifyContent="center" h="100%">
                                <ExpressOnlineOrderHeader
                                    setExpressOnlineDisplaySettings={setExpressOnlineDisplaySettings}
                                    expressOnlineDisplaySettings={expressOnlineDisplaySettings}
                                    shopIsClosed={browseMode.isClosed}
                                    headerOpen={headerOpen}
                                    selectedShop={selectedShop as Shop}
                                    foodOptions={foodOptions}
                                    handleEatingOptionClick={handleEatingOptionClick}
                                    setHeaderOpen={setHeaderOpen}
                                    onStartOver={onStartOver}
                                    onScrollAndStartOver={onScrollAndStartOver}
                                    eatingOptions={uniqueEatingOptions}
                                    isCompanyLanding={isCompanyLanding}
                                    webVersion={webFrontendVersion}
                                    hasActiveMenu={hasActiveMenu}
                                />
                            </Flex>
                        </Box>
                    </Box>
                    {isPortrait && headerOpen && (
                        <Flex
                            bg="white"
                            position={"absolute"}
                            bottom={0}
                            width="100%"
                            height="60px"
                            alignItems={"center"}
                            zIndex={2}
                        >
                            <LanguagePicker
                                hideSelected
                                buttonThemeColor="white"
                                size={isLargeScreen ? "md" : "express"}
                                mr={0}
                            />
                            <Text mb={0} fontSize={{ sm: "1rem", lg: "1.5rem" }}>
                                {translate("changeLanguage")}
                            </Text>
                            <Box position="fixed" left={{ sm: "46%", lg: "45%" }}>
                                <PoweredByQoplaLogo
                                    logoStyles={{ fontSize: { sm: "1.5rem", lg: "2.6rem" } }}
                                    textStyles={{ fontSize: { sm: "0.35rem", lg: "0.6rem" } }}
                                />
                            </Box>
                        </Flex>
                    )}
                </Box>
            </AnimateHeight>
        </>
    );
};

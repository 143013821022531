import React from "react";
import { HiOutlineUser } from "@react-icons/all-files/hi/HiOutlineUser";
import { HiOutlineUserGroup } from "@react-icons/all-files/hi/HiOutlineUserGroup";
import { IoMdExit } from "@react-icons/all-files/io/IoMdExit";
import { MdCheckBoxOutlineBlank } from "@react-icons/all-files/md/MdCheckBoxOutlineBlank";

import { Box, Button, Center, Collapsable, Flex, Header, NewDivider, Stack, Text } from "Atoms";
import { useSwitch } from "Hooks";
import { FullOrderWindowCustomer, useLanguage } from "Providers";
import { OrderWindowOrderType } from "Types";
import { useOrderWindowSocketStore } from "Stores";
import { getReadyOrderWindowCustomersCount } from "../../../../customer/shared/groupOrder/utils";

type Props = {
    handleMakeOrder: (orderType: OrderWindowOrderType, nickname: string, isLastToOrder: boolean) => Promise<void>;
    isReady: boolean;
    canOrder: boolean;
    hasGroup: boolean;
    isInGroupOrder: boolean;
    isLastToOrder: boolean;
    customers: FullOrderWindowCustomer[];
};

export const GroupOnlineOrderFooter: React.FC<Props> = ({
    handleMakeOrder,
    isReady,
    canOrder,
    hasGroup,
    isInGroupOrder,
    isLastToOrder,
    customers
}) => {
    const { open: isOrderTypeSelectionVisible, onOpen: onShowOrderTypes } = useSwitch();
    const { translate } = useLanguage();
    const { nickname, groupOrderNicknames, onSetCustomerAsConnected, onSetCustomerAsReadyToOrder, onLeaveGroupOrder } =
        useOrderWindowSocketStore();

    const handleMakeGroupOrder = () => {
        handleMakeOrder(OrderWindowOrderType.GROUP, nickname as string, isLastToOrder);
    };

    const handleMakeSingleOrder = () => {
        handleMakeOrder(OrderWindowOrderType.SINGLE, nickname as string, isLastToOrder);
    };

    const onPressOrder = () => {
        if (hasGroup) {
            onShowOrderTypes();
        } else {
            handleMakeSingleOrder();
        }
    };

    const onPressUnReady = () => {
        // Marking yourself as not ready from internal group order is the same
        // as setting your status back to CONNECTED
        onSetCustomerAsConnected(nickname as string);
    };

    const onPressLeave = () => {
        onLeaveGroupOrder(nickname as string);
    };

    const onPressReady = () => {
        if (isReady) {
            onPressOrder();
        } else {
            onSetCustomerAsReadyToOrder(nickname as string);

            if (isLastToOrder) {
                handleMakeGroupOrder();
            }
        }
    };

    const numReadyCustomers = getReadyOrderWindowCustomersCount(customers);
    const numCustomersInGroupOrder = groupOrderNicknames?.length ?? 0;
    const isAloneInGroupOrder = numCustomersInGroupOrder === 1;

    return (
        <Box>
            {!isInGroupOrder ? (
                <Collapsable open={isOrderTypeSelectionVisible}>
                    <Flex justify="space-evenly" align="center" p={4}>
                        <Button
                            onClick={handleMakeSingleOrder}
                            flexDirection="column"
                            size="xl"
                            background="transparent"
                            py={6}
                            rounded="lg"
                            h="auto"
                            flex="1"
                            variant="ghost"
                            themeColor="blue"
                        >
                            <Box as={HiOutlineUser} size="3rem" color="blue.500" />
                            <Text as="span" mt={2} color="blue.500">
                                {translate("single")}
                            </Text>
                        </Button>

                        <NewDivider rotate="90deg" w="30px" borderWidth={2} borderColor="gray.500" />
                        <Button
                            onClick={handleMakeGroupOrder}
                            flexDirection="column"
                            size="xl"
                            background="transparent"
                            py={6}
                            rounded="lg"
                            h="auto"
                            flex="1"
                            variant="ghost"
                            themeColor="blue"
                        >
                            <Box as={HiOutlineUserGroup} size="3rem" color="blue.500" />
                            <Text as="span" mt={2} color="blue.500">
                                {translate("together")}
                            </Text>
                        </Button>
                    </Flex>
                </Collapsable>
            ) : isReady ? (
                <Box>
                    <Center bg="green.200" color="gray.900" direction="column" py={3}>
                        <Header mb={3}>{translate("ready")}</Header>
                        <Stack isInline stretch={2} align="center">
                            <Box as={HiOutlineUserGroup} size="2rem" color="gray.900" />
                            <Text fontSize="xl" fontWeight="bold">
                                {numReadyCustomers} / {numCustomersInGroupOrder}
                            </Text>
                        </Stack>
                    </Center>
                    <Button variant="ghost" themeColor="red" bg="red.200" size="lg" fullWidth onClick={onPressUnReady}>
                        {translate("undo")}
                    </Button>
                </Box>
            ) : (
                <Flex justify="space-evenly" align="center">
                    <Button
                        onClick={onPressLeave}
                        flexDirection="column"
                        size="xl"
                        background="transparent"
                        py={6}
                        rounded="lg"
                        h="auto"
                        flex="1"
                        variant="ghost"
                        themeColor="red"
                    >
                        <Box as={IoMdExit} size="3rem" color="red.500" />
                        <Text as="span" mt={2} color="red.500">
                            {translate("leaveGroup")}
                        </Text>
                    </Button>

                    <NewDivider rotate="90deg" w="30px" borderWidth={2} borderColor="gray.500" />
                    <Button
                        onClick={onPressReady}
                        isDisabled={isAloneInGroupOrder}
                        flexDirection="column"
                        size="xl"
                        background="transparent"
                        py={6}
                        rounded="lg"
                        h="auto"
                        flex="1"
                        variant="ghost"
                        themeColor="blue"
                    >
                        <Box as={MdCheckBoxOutlineBlank} size="3rem" color="blue.500" />
                        <Text as="span" mt={2} color="blue.500">
                            {translate("ready")}
                        </Text>
                    </Button>
                </Flex>
            )}
            <Stack stretch={6}>
                {!isOrderTypeSelectionVisible && !isInGroupOrder && (
                    <Box p={4}>
                        <Button
                            isDisabled={!canOrder}
                            onClick={onPressOrder}
                            size="lg"
                            rounded="lg"
                            fullWidth
                            themeColor="blue"
                        >
                            {translate(hasGroup ? "order" : "orderNow")}
                        </Button>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

import React, { useEffect } from "react";
import moment from "moment";

import { useEffectOnce, useSessionStorage } from "Hooks";
import { useMothershipLazyQuery } from "Hooks";
import { Box, Text, Image, Flex } from "Atoms";
import { UserGiftCard, GiftCard } from "Types";
import { SessionConstants } from "Constants";
import { useLanguage } from "Providers";
import { GET_PUBLIC_SHOP_INFOS_BY_IDS } from "GraphQLQueries";

type Props = {
    userGiftCard: UserGiftCard;
    giftCardSettings: Partial<{ shopName: string; shopImageUrl: string }> & GiftCard;
};

export const UserGiftCardInformation: React.FC<Props> = ({ userGiftCard, giftCardSettings }) => {
    const [, , removeGiftCardState] = useSessionStorage(SessionConstants.GIFT_CARD_VALUES);
    const { translate } = useLanguage();
    const senderName = userGiftCard?.buyerInformation?.onlineContactInformation?.name ?? "";

    const [getShopInfosByIds, { data: publicShopResults }] = useMothershipLazyQuery(GET_PUBLIC_SHOP_INFOS_BY_IDS);

    useEffectOnce(() => {
        removeGiftCardState();
    });

    useEffect(() => {
        if (userGiftCard.shopId && !giftCardSettings.shopName) {
            getShopInfosByIds({
                variables: { shopIds: userGiftCard.shopId }
            });
        }
    }, [userGiftCard.shopId]);

    const imgURL = giftCardSettings?.imageUrl;

    if (!giftCardSettings.shopName) {
        giftCardSettings.shopName = publicShopResults?.getPublicShopInfosByIds[0].name;
        giftCardSettings.shopImageUrl = publicShopResults?.getPublicShopInfosByIds[0].imageUrl;
    }

    if (!giftCardSettings || !userGiftCard) return null;

    return (
        <Box h="100%">
            <Flex backgroundColor="white" boxShadow="md" rounded="lg" direction="column">
                <Box
                    position="relative"
                    roundedTop="lg"
                    height={{ xs: "220px", md: "280px" }}
                    overflow="hidden"
                    backgroundColor="black"
                >
                    <Image src={imgURL} roundedTop="lg" maxH="100%" objectFit="cover" opacity={0.4} />

                    <Flex
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"
                        height="100%"
                        color="white"
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        py={2}
                    >
                        <Flex alignItems="center" h="auto">
                            <Box height={{ xs: "3.5rem", lg: "5rem" }}>
                                <Image
                                    src={giftCardSettings?.shopImageUrl}
                                    rounded="lg"
                                    maxH="100%"
                                    objectFit="cover"
                                />
                            </Box>
                            {giftCardSettings?.name !== "Digitalt Presentkort" && (
                                <Text ml={2}>{giftCardSettings?.name}</Text>
                            )}
                        </Flex>
                        <Text mb={0} fontFamily="giftcard" textAlign="center" fontSize={{ xs: "3rem", lg: "5rem" }}>
                            {translate("giftCard")}
                        </Text>
                        <Text fontSize="3rem" mb={4}>
                            {userGiftCard.initialAmount} kr
                        </Text>
                        <Text fontSize="1rem">
                            {translate("validTo").toUpperCase()}{" "}
                            {moment(userGiftCard.expirationDate).format("YYYY-MM-DD")}
                        </Text>
                    </Flex>
                </Box>
                <Box px={6} py={4}>
                    <Flex>
                        <Text
                            mb={1}
                            fontSize="lg"
                            fontWeight="600"
                            width={{ xs: "4rem", lg: "8rem" }}
                            textTransform="capitalize"
                        >
                            {translate("from")}:
                        </Text>
                        <Text fontSize="xl" textTransform="capitalize">
                            {senderName}
                        </Text>
                    </Flex>
                    <Flex my={2}>
                        <Text
                            mb={1}
                            fontSize="lg"
                            fontWeight="600"
                            width={{ xs: "4rem", lg: "8rem" }}
                            textTransform="capitalize"
                        >
                            {translate("to")}:
                        </Text>
                        <Text mb={1} fontSize="xl" textTransform="capitalize">
                            {userGiftCard.recipientDetails.recipientName}
                        </Text>
                    </Flex>
                    <Flex flexDirection={{ xs: "column", lg: "row" }}>
                        <Text mb={1} fontSize="lg" fontWeight="600" width={{ xs: "100%", lg: "8rem" }}>
                            {translate("message")}:
                        </Text>

                        <Text maxW={{ xs: "100%", lg: "75%" }} whiteSpace="pre-line">
                            {userGiftCard.recipientDetails.recipientMessage}
                        </Text>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

import React from "react";
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle";

import { OnlineInformationContainer } from "OnlineComponents";
import { useLanguage } from "Providers";
import { Text } from "Atoms";

export const InvalidLinkWarning = () => {
    const { translate } = useLanguage();

    return (
        <OnlineInformationContainer icon={FaExclamationTriangle} headerText={`${translate("invalidLink")}`}>
            <Text>{translate("contactUs")}</Text>
        </OnlineInformationContainer>
    );
};

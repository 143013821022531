import React from "react";
import { BiRefresh } from "@react-icons/all-files/bi/BiRefresh";
import { useForm } from "react-hook-form";

import { Flex, Button, Stack, Checkbox, Box, RHSwitchInput, RHCheckBoxGroupInput } from "Atoms";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { EatingPreference, useLanguage } from "Providers";
import { LanguageHeader } from "Components";
import { usePosStore, useQoplaStore } from "Stores";
import { ExpressOnlineSettingsType } from "../../../../utils";

type Props = {
    expressOnlineDisplaySettings: ExpressOnlineSettingsType;
    onToggleOpenModal: () => void;
    handleUpdateLocalStorage: (values: {}) => void;
    webVersion: string;
};

export const ExpressOnlineSecretModal: React.FC<Props> = ({
    expressOnlineDisplaySettings,
    onToggleOpenModal,
    handleUpdateLocalStorage,
    webVersion
}) => {
    const { selectedShop } = useQoplaStore();
    const onlineSettings = selectedShop?.settings?.onlineSettings;
    const shopEatingOptions = onlineSettings?.eatingOptions ?? [];
    const { selectedPos } = usePosStore();

    const { translate } = useLanguage();

    const eatingOptions = [
        { label: translate("eatHere"), value: EatingPreference.EAT_HERE },
        { label: translate("takeAway"), value: EatingPreference.TAKE_AWAY }
    ];

    const defaultValues = {
        lightMode: expressOnlineDisplaySettings.lightMode ?? false,
        eatingOptions: expressOnlineDisplaySettings.eatingOptions || shopEatingOptions,
        animation: expressOnlineDisplaySettings?.animation ?? false
    };

    const { control, handleSubmit } = useForm<ExpressOnlineSettingsType>({
        mode: "onBlur",
        defaultValues
    });

    const onSubmit = (values: ExpressOnlineSettingsType) => {
        handleUpdateLocalStorage(values);
        onToggleOpenModal();
    };

    return (
        <Modal open rounded="md" size="2xs" closeOnEscape>
            <ModalHeader>
                <LanguageHeader tid="settings" as="h3" fontSize="3xl" textAlign="center" />
                <ModalCloseBtn onClick={onToggleOpenModal} />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack stretch={4}>
                        <RHCheckBoxGroupInput
                            groupHeader={translate("pickupSelection")}
                            name="eatingOptions"
                            control={control}
                            options={eatingOptions}
                            isMulti
                            showSelectAndUnselectAll={false}
                            size={"lg"}
                            wrapperProps={{ fontSize: "xl" }}
                        />
                        <RHSwitchInput
                            formLabel={translate("lightBackground")}
                            control={control}
                            name="lightMode"
                            size="smalltouch"
                            wrapperProps={{ alignItems: "center", justifyContent: "space-between", fontSize: "lg" }}
                        />
                        <RHSwitchInput
                            formLabel={translate("animation")}
                            control={control}
                            name="animation"
                            size="smalltouch"
                            wrapperProps={{ alignItems: "center", justifyContent: "space-between", fontSize: "lg" }}
                        />
                        <Flex mb={4} justify="space-between" alignItems="baseline">
                            <Button
                                leftIcon={BiRefresh}
                                onClick={() => {
                                    location.reload();
                                }}
                            >
                                {translate("reload")}
                            </Button>
                        </Flex>
                        <Flex mb={4} justify="space-between" alignItems="baseline">
                            {selectedPos?.name && <Box>Kassa: {selectedPos.name}</Box>}
                            <Box>Version: {webVersion}</Box>
                        </Flex>
                    </Stack>
                    <Button type="submit" themeColor="green" mt={6} fullWidth>
                        {translate("save")}
                    </Button>
                </form>
            </ModalBody>
        </Modal>
    );
};
